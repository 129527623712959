<template>
	<Fragment>
		<carousel
			:paginationEnabled="false"
			:perPage="1"
			:autoplay="true"
			:loop="true"
			:autoplayTimeout="6000"
			:navigationClickTargetSize="0"
		>
			<slide v-for="(item, index) in banners" :key="index" tabindex="1">
				<img :src="item.imgUrl" alt="" @click="handleSlideClick(item)" />
				<div class="banner-status">
					<p>{{ index + 1 }} / {{ banners.length }}</p>
				</div>
			</slide>
		</carousel>
	</Fragment>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
	name: 'TopBanner',
	components: { Carousel, Slide },
	props: ['banners'],
	methods: {
		handleSlideClick(item) {
			window.open(item.link);
		},
	},
};
</script>

<style scoped></style>
