<template>
	<div @click="handleBackdrop">
		<template v-if="isBanner">
			<div class="top-banner-notice" id="ad" style="background-color: #fff2ab">
				<button class="close" @click="closeBanner"><i class="ic-24 ic-close-thin"></i></button>
				<top-banner :banners="topBanners"></top-banner>
			</div>
		</template>

		<MainHeader @openMenu="openMenu" :close="close" />
		<div id="scrollBody" class="body-wrap" :class="className">
			<MainGnb @closeMenu="closeMenu" :open="open" />
			<router-view />
			<MainFooter />
		</div>
	</div>
</template>

<script>
import MainFooter from '@/views/common/MainFooter.vue';
import MainGnb from '@/views/common/MainGnb.vue';
import MainHeader from '@/views/common/MainHeader.vue';
import { HOME } from '@/constants';
import backdrop from '@/components/common/backdrop';
import { promotion } from '@/views/common/promotion';
import TopBanner from '@/views/TopBanner';

export default {
	name: 'Layout',
	mixins: [backdrop, promotion],
	components: { TopBanner, MainFooter, MainGnb, MainHeader },
	data() {
		return {
			className: this.$route.name === HOME ? 'main' : '',
			topBanners: [],
			isBanner: false,
			open: '',
			close: {},
		};
	},
	watch: {
		$route: {
			handler(to, from) {
				this.className = to.name === HOME ? 'main' : '';
			},
		},
	},
	async created() {
		await this.getTodayPromotions().catch(() => {});
		this.topBanners = this.$store.getters.getTopBanner;
		if (this.topBanners.length > 0) {
			this.appendBanner();
		}

		if (this.$store.getters.getCustomerTermsList.length === 0) {
			await this.$store.dispatch('setCustomerTermsList');
		}
	},

	methods: {
		// todo: 해당코드는 body를 강제로 접근하여 핸들링함, 좋지않은 방식. HTML 구조상 불가피하여 해당 코드 추가함.
		appendBanner() {
			document.body.className = 'has-top-notice';
			this.isBanner = true;
		},
		closeBanner() {
			document.body.className = '';
			this.isBanner = false;
		},
		openMenu() {
			this.open = 'open';
		},
		closeMenu() {
			this.open = '';
			this.close = { openGnb: '', blindLogo: '' };
		},
	},
};
</script>

<style scoped></style>
