<template>
	<header :class="{ 'gnb-open': openGnb }">
		<div class="logo" :class="{ blind: blindLogo }">
			<a @click="$router.push('/home')" class="logo-link">
				<i class="img-logo"></i>
				고객 포털
			</a>
		</div>
		<!-- util area : 로그인 후, util 영역 -->
		<!-- 상태값 확인하여 노출 시작-->
		<div class="util rgt" v-if="isCurrentUser" ref="backdropParent">
			<div class="user-info">
				<div class="avatar">
					<img v-if="$store.getters.hasProfile" :src="$store.state.login.currentUser.profileUrl" alt="" />
				</div>
				<button class="ghost circle" @click.stop="handleBackdropButtonClick">
					<i class="ic-16 ic-arr-down-bold"></i>
				</button>
			</div>
			<ul class="layer menu" v-bind:class="isOpenedLayer ? 'on' : 'off'" @click.stop="handleLayerClick">
				<li @click="$router.push('/profile')">내 정보 관리</li>
				<li @click="logout">로그아웃</li>
			</ul>

			<!-- @mobile 해상도 용 추가 -->
			<div class="btn-drawer">
				<button class="drawer-open" @click="openMenu()">
					<i class="ic-24 ic-drawer"></i>
					메뉴 오픈
				</button>
			</div>
		</div>
		<!-- 상태값 확인하여 노출 끝-->
	</header>
</template>
<script>
import backdrop from '@/components/common/backdrop';
import _ from 'lodash';

export default {
	name: 'main-header',
	mixins: [backdrop],
	props: {
		close: { type: Object, default: null },
	},
	data() {
		return {
			isMembership: this.$store.getters['isMemberShipUser'],
			isCurrentUser: this.$store.getters['isCurrentUser'],
			userProfile: this.$store.getters['userProfile'],
			isMenu: 'off',
			openGnb: '',
			blindLogo: '',
		};
	},
	computed: {
		hasProfileUrl() {
			return !_.isEmpty(this.$store.getters.userProfileUrl);
		},
	},
	methods: {
		async logout(e) {
			e.preventDefault();
			await this.$router.push('/logout');
		},
		handleLayerClick() {
			this.isOpenedLayer = false;
		},
		openMenu() {
			this.openGnb = 'gnb-open';
			this.blindLogo = 'blind';
			this.$emit('openMenu');
		},
	},
	watch: {
		close() {
			this.openGnb = '';
			this.blindLogo = '';
		},
	},
};
</script>
<style>
@import '../../assets/css/common.css';
@import '../../assets/css/main.css';
</style>
