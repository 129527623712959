<template>
	<div class="gnb system-link" :class="{ open: openMenu }">
		<!-- @mobile 해상도용 gnb 닫기 버튼 -->
		<div class="btn-drawer">
			<button class="drawer-close" @click="closeMenu()">
				<i class="ic-24 ic-close"></i>
				메뉴 닫기
			</button>
		</div>

		<!-- navigation -->
		<!-- home : flip [x]-->
		<div class="menu" @click="activeTab = 'HOME'">
			<a @click="goToLink('/home')" class="first home">Home</a>
		</div>

		<!-- 회사정보관리 -->
		<div v-if="!isAssociateIndividual" class="menu has-sub-menu open">
			<a class="first company" @click="$event.target.parentElement.classList.toggle('open')">회사정보관리</a>
			<!-- second -->
			<ul class="sub-menu">
				<li @click="activeTab = 'COMPANYINFO'">
					<a
						@click="goToLink('/user/myStore/CompanyInfo')"
						class="second"
						:class="activeTab === 'COMPANYINFO' ? 'active' : ''"
						>회사법인정보관리</a
					>
				</li>
				<li v-if="isRegularCustomer" @click="activeTab = 'USERS'">
					<a @click="goToLink('/users')" class="second" :class="activeTab === 'USERS' ? 'active' : ''"
						>사용자초대/관리</a
					>
				</li>
			</ul>
		</div>

		<!-- 멤버십 현황 -->
		<div v-if="!isAssociateIndividual" class="menu has-sub-menu open">
			<a class="first membership" @click="$event.target.parentElement.classList.toggle('open')">멤버십 현황</a>
			<!-- second -->
			<ul class="sub-menu">
				<template>
					<li v-if="isRegularIndividual || isRegularCustomer" @click="activeTab = 'MEMBERSHIPSTATUS'">
						<a
							@click="goToLink('/user/myStore/MembershipStatus')"
							class="second"
							:class="activeTab === 'MEMBERSHIPSTATUS' ? 'active' : ''"
							>멤버십현황</a
						>
					</li>
				</template>
				<li @click="activeTab = 'ORDERHISTORY'">
					<a
						@click="goToLink('/user/myStore/OrderHistory')"
						class="second"
						:class="activeTab === 'ORDERHISTORY' ? 'active' : ''"
						>주문/구매내역</a
					>
				</li>
			</ul>
		</div>

		<!-- 주문 및 결제 -->
		<div class="menu has-sub-menu open">
			<a class="first order" @click="$event.target.parentElement.classList.toggle('open')">주문 및 결제</a>
			<!-- second -->
			<ul class="sub-menu">
				<li @click="activeTab = 'APPLY'">
					<a
						@click="goToLink('/apply/step1?servicetype=SAAS')"
						class="second"
						:class="activeTab === 'APPLY' ? 'active' : ''"
						>신규주문</a
					>
				</li>
				<template v-if="isRegularCustomer || isRegularIndividual">
					<li @click="activeTab = 'ORDER'">
						<a @click="goToLink('/order/addExtension')" class="second" :class="activeTab === 'ORDER' ? 'active' : ''"
							>추가/연장주문</a
						>
					</li>
				</template>
			</ul>
		</div>

		<!-- 서비스 관리 -->
		<template v-if="!isAssociateIndividual">
			<div class="menu has-sub-menu open">
				<a class="first service" @click="$event.target.parentElement.classList.toggle('open')">서비스 관리</a>
				<!-- second -->
				<ul class="sub-menu">
					<!--					<li class="external-links"><a class="second" @click="goToOpenlounge">개설관리</a></li>-->
					<li @click="activeTab = 'SITESTATUS'">
						<a
							@click="goToLink('/user/myStore/SiteStatus')"
							class="second"
							:class="activeTab === 'SITESTATUS' ? 'active' : ''"
							>시스템현황</a
						>
					</li>
				</ul>
			</div>
		</template>

		<!-- 고객포털 FAQ -->
		<div class="menu has-sub-menu open">
			<a class="first faq" @click="$event.target.parentElement.classList.toggle('open')">고객포털 FAQ</a>
			<!-- second -->
			<ul class="sub-menu">
				<li class="external-links">
					<a :href="careLoungeUrl.faq" target="_blank" class="second"> 고객포털FAQ </a>
				</li>
				<!--				<li class="external-links">-->
				<!--					<a :href="careLoungeUrl.contactUs" target="_blank" class="second">고객포털1:1문의</a>-->
				<!--				</li>-->
			</ul>
		</div>
	</div>
</template>
<script>
import { getOpenloungeAuth } from '@/api/openlounge';
import { CARELOUNGEURL } from '@/constants';

export default {
	name: 'main-gnb',
	props: ['open'],
	data() {
		return {
			selected: '',
			openloungeURL: process.env.VUE_APP_OPENLOUNGE_URL,
			activeTab: '',
			gnbList: [
				'HOME',
				'COMPANYINFO',
				'USERS',
				'MEMBERSHIPSTATUS',
				'ORDER_HISTORY',
				'APPLY',
				'ORDER',
				'SITESTATUS',
				'PROFILE',
			],
			openMenu: '',
		};
	},
	computed: {
		careLoungeUrl() {
			return {
				faq: `${CARELOUNGEURL}/hc/ko`,
				contactUs: `${CARELOUNGEURL}/hc/ko/requests/new`,
			};
		},
		// isMembership() {
		// 	return this.$store.getters['isMemberShipUser'];
		// },
		// regular: 정회원, associate: 준회원
		isRegularCustomer() {
			return this.$store.getters['isMemberShipUser'] && this.$store.getters['isSuperAdmin'];
		},
		isRegularIndividual() {
			return this.$store.getters['isMemberShipUser'] && this.$store.getters['isAdmin'];
		},
		isAssociateCustomer() {
			return !this.$store.getters['isMemberShipUser'] && this.$store.getters['isSuperAdmin'];
		},
		isAssociateIndividual() {
			return (
				!this.$store.getters['isMemberShipUser'] &&
				!this.$store.getters['isSuperAdmin'] &&
				!this.$store.getters['isAdmin']
			);
		},
	},
	watch: {
		$route(to, from) {
			let pathList = to.path.split('/');
			pathList.forEach((path) => {
				let result = this.gnbList.find((element) => element === path.toUpperCase());
				if (result) {
					this.activeTab = result.toUpperCase();
					return false;
				}
			});
		},
		open() {
			this.openMenu = this.open;
		},
	},
	methods: {
		goToOpenlounge() {
			const result = getOpenloungeAuth();
			result
				.then((result) => {
					const code = result.data;
					if (!code) {
						this.$toasted.show('개설라운지에 접근 할 수 없습니다.');
						return;
					}
					const href = `${this.openloungeURL}/sso/portal?token=${code}`;
					window.open(href);
				})
				.catch(() => {
					const href = `${this.openloungeURL}`;
					window.open(href);
				});
		},
		closeMenu() {
			this.openMenu = '';
			this.$emit('closeMenu');
		},
		goToLink(path) {
			this.closeMenu();
			this.$router.push(path);
		},
	},
};
</script>
<style>
@import '../../assets/css/common.css';
/*@import '../../assets/css/main.css';*/
/*@import '../../assets/css/vue-carousel.css';*/
</style>
